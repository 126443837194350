import Vue from 'vue'
import VueRouter from 'vue-router'
import homePage from '@/views/dashboard/homePage'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
    return originalPush.call(this,location).catch(err => err)
}
const routes = [
    { 
        path: '/', 
        redirect: '/homePage'
    },
    { 
        path: '/homePage', 
        component: () => import('@/views/dashboard/homePage')
    },
    {
        path: '/aboutUs',
        component: () => import('@/views/aboutUs/index')
      },

]

const router = new VueRouter({
    routes,
    // mode:'history',
})
export default router