<template>
  <div class="header">
    <div class="public-wrap">
      <div class="left">
        <img class="logo" :src="logoUrl==1?homeLogo:aboutsLogo" alt="" />
      </div>
      <div class="right">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          active-text-color="#fff"
          router
        >
          <!-- <el-menu-item index="/homePage">首页</el-menu-item>
          <el-menu-item index="/aboutUs">关于我们</el-menu-item> -->
          <!-- <el-menu-item>
            <a href="http://h5.qiuxiangbiao.cc/mp/h5/qxb.html?id=c19999" style="text-decoration:none" target="_blank" >下载APP</a>
          </el-menu-item> -->
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    logoUrl:Number
  },
  data() {
    return {
      activeIndex: 'aboutUs',
      homeLogo:require('../../assets/logoPc.png'),
      aboutsLogo:require('../../assets/logoPc.png'),
    };
  },
  created(){
    this.init()
  },
  methods: {
   asd(){
    console.log("123")
   },
    init(){
       // 解决element-ui导航需点击才高亮问题
      let url = this.$route.path
      let arr = url.split('/')
      this.activeIndex ='/' + arr[1]
      // this.logoUrl = require('../../assets/logo.png')
    },
    handleSelect(key, keyPath) {
        // console.log(key, keyPath);
      }
  },
};
</script>
<style lang="scss" scoped>
.header {
  .public-wrap {
    
	  .left{
      float: left;
		  .logo{
			  // width: 110px;
			  height: 32px;
		  }
	  }
    .right{
      float: right;
      .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
        color: #fff;
      }
    }
    width: 1070px;
    height: 60px;
    margin: auto;
    // background: aqua;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-menu-demo{
      border: none;
      background: transparent;
      .el-menu-item.is-active{
        border: none;
      }
      .el-menu-item:hover{
        background: none;
      }
    }
  }
}
// .header {
//   height: 60px;
//   line-height: 60px;
//   background-color: #fff;
//   .public-wrap{
//     width: 1070px;
//     margin: auto;
//     position: relative;
//     .left{
//       float: left;
//       margin-left: 50px;
//       font-size: 14px;
//       color: #333;
//      .logo{
//       height: 32px;
//       vertical-align: -10px;
//      }
//     }
//     .right{
//       float: right;
//       margin-right: 50px;
//       font-size: 14px;
//     }
//   }
//   a {
//     font-size: 14px;
//     text-decoration: none;
//     margin: 0 10px;
//     color: #eee;
//   }

// }
// 屏幕宽度小于1024触发
@media screen and (max-width: 1024px) {
  .header {
    .left .logo{
      width: 80px;
    }
  }
}
</style>
