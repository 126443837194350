<template>
  <div id="footer">
    <div class="public-wrap">
      <div class="content">
        <!-- <a
          target="_blank"
          href="https://www.qiudasheng.com/qds/serviceAgreement.html"
          >服务协议</a
        >
        <el-divider direction="vertical"></el-divider>
        <a
          target="_blank"
          href="https://www.qiudasheng.com/qds/privacyPolicy.html"
          >隐私政策</a
        > -->
      </div>
      <!-- <div style="margin: 0 50px">
        <el-divider></el-divider>
      </div> -->
      <div class="content">
        <p style="margin-bottom: 10px">
          广西知音文化传媒有限公司
          <span style="margin: 0 0 0 10px">Copyright</span>
          <span style="margin-right: 4px">©</span>2023 广西知音 All Rights Reserved
        </p>
        <div style="color: #939393;">
          <div>
            公司地址：中国（广西）自由贸易试验区南宁片区盘歌路8号大唐国际中心1号楼五层519号办公室
            </div>
            <div>联系电话：0574-28803298</div>
        </div>
        <div style="width: 500px; margin: 0 auto;display:flex;justify-content:center;align-items:center; padding: 20px 0">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45010002450168"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
            ><img :src="beian" style="float: left" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
            备案号：桂ICP备45010002450168号
            </p></a
          >
          <!-- <el-divider direction="vertical"></el-divider> -->
          <!-- <a href="https://beian.miit.gov.cn">浙ICP备2022029315号-1</a> -->
<!--          <a href="https://beian.miit.gov.cn">浙ICP备2023015275号-1</a>-->
        </div>
        <!-- <span style="margin-right:10px">客服QQ：1246786881</span>
        <span>客服微信：QXB_kefu</span> -->

        <!-- <div style="width: 600px; margin: 0 auto; display: flex;justify-content: center;align-items: center;margin-top: 14px;"> -->
        <!-- <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202002248"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
            ><img :src="beian" style="float: left;width:20px" />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #939393;
              "
            >
              浙公网安备 33021202002248号
            </p></a
          > -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      beian: require("../../assets/beian.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  background-color: #000;
  font-size: 14px;
  color: #ccc;
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .content {
    a {
      color: rgb(147, 147, 147);
      text-decoration: none;
    }
    .el-divider {
      color: rgb(147, 147, 147);
      background: rgb(147, 147, 147);
    }
  }
  p {
    margin-bottom: 0;
    // font-size: #ccc;
    color: rgb(147, 147, 147);
  }
}
// 屏幕宽度小于768或等于触发
@media screen and (max-width: 768px) {
  #footer {
    font-size: 12px;
  }
}
</style>
