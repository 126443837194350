<template>
  <div id="home">
    <div class="index-bg">
      <headers :logoUrl="logoUrl"></headers>
      <div class="public-wrap">
        <div class="text">
          <h3>球客岛APP</h3>
          <h1>带你看懂比赛方向!</h1>
        </div>
        <div class="download">
          <img class="image" src="../../assets/homeDow.png" alt="" />
        </div>
      </div>
    </div>
    <div class="centent">
      <div class="module info">
        <div class="public-wrap">
          <div class="moduleImg">
            <img src="../../assets/info.png" alt="" style="width: 540px;"/>
          </div>
          <div class="text">
            <h1>体育比赛</h1>
            <h3>覆盖全球主流赛事</h3>
          </div>
        </div>
      </div>
      <div class="module match">
        <div class="public-wrap">
          <div class="text" style="text-align:right">
            <h1>全面数据</h1>
            <h3>顶级赛事专业数据</h3>
          </div>
          <div class="moduleImg" style="text-align:left">
            <img src="../../assets/Frame.png" alt="" style="width: 540px;" />
          </div>
        </div>
      </div>
      <div class="module data">
        <div class="public-wrap">
          <div class="moduleImg">
            <img src="../../assets/data.png" alt="" style="width: 540px;" />
          </div>
          <div class="text">
            <h1>赛事实况</h1>
            <h3>掌握球场实时动态</h3>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header";
import footers from "@/components/footer";
export default {
  components: {
    headers,
    footers,
  },
  data() {
    return {
      logoUrl: 1,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#home {
  .index-bg {
    background: url("../../assets/carousel.jpg") center no-repeat;
    background-size: auto 100%;
    background-color: #000;
    .public-wrap {
      height: 378px;
      justify-content: center;
      
      .text {
        color: #fff;
        text-align: left;
        margin-bottom: 50px;
        margin-right: 154px;
        h3{
          font-weight: normal;
          font-size: 22px;
          margin-bottom: 10px;
          color: #eee;
        }
        h1{
          font-size: 40px;
          margin-top: 10px;
        }
      }
      .download {
        z-index: 999;
        margin-top: 50px;
        margin-right: 40px;
        .image {
          width: 524px;
          height: auto;
        }
      }
    }
  }
  .centent {
    .module {
      .public-wrap {
        // background: url("../../assets/资讯.jpg") 40% center  no-repeat;
        // background-size: auto 82%;
        height: 390px;
        justify-content: center;
        .moduleImg {
          height: 100%;
          width: 50%;
          text-align: right;
          // img {
          //   width: 50%;
          // }
        }
        .text {
          width: 46%;
          padding-left: 50px;
          h1{
            font-size: 40px;
          }
          h3{
            color: #999;
            font-size: 22px;
            font-weight: normal;
          }
        }
      }
    }
    // .info .moduleImg{
    //   background: url("../../assets/info.jpg") center no-repeat;
    //   background-size: 100% auto;
    // }
    .match .text{
      padding-right: 50px;
    }
  }
  .public-wrap {
    width: 100%;
    height: 60px;
    // background: aqua;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
// @media screen and (max-width: 1024px){

// }
// 屏幕宽度小于430px
@media screen and (max-width: 1024px) {
  #home {
    .index-bg {
    .public-wrap {
      height: 200px;
      justify-content: space-evenly;
      
    }
  }
    .centent {
      .module {
        .public-wrap {
          // background: url("../../assets/资讯.jpg") 40% center  no-repeat;
          // background-size: auto 82%;
          height: auto;
          align-items: center;
          .moduleImg {
            height: auto;
            img {
              width: 100%;
            }
          }
          .text {
            width: 50%;
            padding: 0 10px;
            h1{
              font-size: 22px;
            }
            h3{
              font-weight: normal;
              color: #999;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
